import { useQuery, gql } from '@apollo/client';
import classnames from 'classnames';
import React, { MouseEvent, FC, memo, ReactNode } from 'react';
import { Link } from 'react-router-dom';

import { isWindow } from 'common/helpers/helpers';
import rightArrow from 'common/icons/arrow_right_blue.svg';
import categoryIcon from 'uikit/Icons/Categories';
import arrayMove from 'utils/arrayMove';
import { AbstractCategory } from 'utils/graphql/categories';
import routes from 'utils/routeTranslator';

import './TopCategories.scss';

type TopCategory = AbstractCategory;

export type TopCategoriesProps = {
  className?: string;
  inline?: boolean;
  homepage?: boolean;
  activeCategorySlug?: string;
  shouldUseNativeNavigation?: boolean;
  city?: string;
  routeL2?: boolean;
  mode?: 'dark';
};

type TopCategoriesResponse = {
  topCategoryList: TopCategory[];
};

const topCategoriesQuery = gql`
  query TopCategoryList {
    topCategoryList(showHiddenCategories: false) {
      id
      name
      slug
    }
  }
`;

const highlightedCategories: string[] = ['montaz-i-naprawa'];

const TopCategories: FC<TopCategoriesProps> = (props) => {
  const { data } = useQuery<TopCategoriesResponse>(topCategoriesQuery);
  if (!data) return null;

  const handleLinkClick = (e: MouseEvent<HTMLAnchorElement>) => {
    if (!props.shouldUseNativeNavigation || !isWindow) {
      return;
    }
    e.preventDefault();
    e.stopPropagation();

    window.location.href = e.currentTarget.href;
  };

  const renderCategory = ({ id, name, slug }: TopCategory): ReactNode => {
    const topCategoryCN = classnames('topCategories__category', {
      topCategories__category_highlighted: highlightedCategories.includes(slug),
      topCategories__category_homepage: props.homepage,
    });

    const topCategoryLinkCN = classnames('topCategories__categoryLink', {
      topCategories__categoryLink_active: props.activeCategorySlug === slug,
    });

    const url = routes.get('front::categories.type', {
      slug,
      city: props.city,
    });

    const urlL2 = routes.get('front::provider.register.l2', {
      slug,
    });

    return (
      <li className={topCategoryCN} key={id}>
        <Link to={props.routeL2 ? urlL2 : url} className={topCategoryLinkCN} onClick={handleLinkClick}>
          <span className="topCategories__categoryIcon">{categoryIcon(slug)}</span>
          <span className="topCategories__categoryName">
            <span>{name}</span>
          </span>
          {props.homepage && <img src={rightArrow} className="topCategories__arrowIcon" alt="" aria-hidden />}
        </Link>
      </li>
    );
  };

  const customSortedList = arrayMove<TopCategory>(data.topCategoryList, 5, 14);
  const topCategoriesCN = classnames('topCategories', props.className, {
    topCategories_inline: props.inline,
    topCategories_homepage: props.homepage,
    topCategories_dark: props.mode === 'dark',
  });

  return <ul className={topCategoriesCN}>{customSortedList.filter(Boolean).map(renderCategory)}</ul>;
};

export default memo(TopCategories);
